.password-reset {
  &__item {
    input[type='text'],
    input[type='password'] {
      display: block;
      width: 300px;
    }
  }
}

.upload-file {
  &__value {
    left: 215px;
  }
  &__button {
    #form--profile--field--UPLOADED_FILE {
      width: 205px;
    }
  }
}

.account-page {
  &__content {
    .newsletter_text {
      margin-bottom: 0;
    }
    .section-header:before {
      background: none;
    }
  }
}

.profile-page {
  &__content {
    .title_container {
      float: none;
    }
  }
}

#address_form_container {
  .address-overlay {
    &-billing,
    &-shipping {
      input[type='text'],
      select {
        width: 100%;
        margin-bottom: 10px;
      }
      .form_element {
        margin-bottom: 6px;
      }
      .title_container,
      .phone_label_container,
      .country_container {
        margin-bottom: 10px;
      }
    }
  }
  .title_container {
    span label.error {
      color: #000000;
    }
  }
}

.order-details-page {
  &__content {
    .cart-item {
      &__qty-label {
        display: none;
      }
      &__total {
        text-align: right;
      }
    }
    .cart-item:last-child {
      border-bottom: 1px solid #bbbbb9;
    }
    .order-totals {
      width: 55%;
      td {
        text-align: right;
      }
    }
  }
}

.create-wishlist {
  &__name {
    width: 62%;
  }
}

.favorites {
  &__recommended-products {
    .formatted_price {
      height: 50px;
    }
    .touch & {
      .product_name {
        a {
          border: none;
        }
      }
    }
  }
  &__instruction-step-title {
    line-height: 30px;
  }
}

.favorite-actions {
  top: 60px;
}

.sign-in {
  .account-links {
    &__profile,
    &__orders,
    &__favorites,
    &__loyalty,
    &__login,
    &__logout {
      height: auto;
    }
  }
}

.password-sent {
  &__text {
    .sent-info__text {
      margin: 0px 0px 10px 20px;
    }
  }
}

.favorites-page {
  .my-lists__list-link {
    &--print {
      vertical-align: top;
    }
    &--email {
      vertical-align: top;
    }
    &--share {
      vertical-align: top;
    }
  }
}

.account {
  #cboxClose {
    top: 10px;
    right: 5px;
  }
}

.order-details-page {
  .order-details-page__content {
    .order-products {
      .cart-item {
        @media screen and (max-width: 768px) {
          .cart-item__thumb {
            width: 12%;
          }
        }
        @media screen and (max-width: 768px) {
          .cart-item__desc {
            width: 31%;
          }
        }
        .cart-item__price {
          width: 20%;
        }
        .cart-item__qty {
          width: 15%;
          margin: 0px;
          .cart-item__qty-label {
            display: block;
            float: left;
            margin-right: 5px;
          }
        }
        .cart-item__total {
          width: 17%;
        }
      }
    }
  }
}

.ie8 {
  #registration_short {
    input[name='PASSWORD-VISIBLE'] {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
  .account-page {
    .account-page__content {
      section {
        word-wrap: break-word;
        .section-header {
          padding: 0px;
          display: block;
          margin-bottom: 20px;
        }
        h3 {
          margin-bottom: 20px;
          padding-bottom: 5px;
        }
      }
      .account-address {
        .section-content {
          padding-left: 3px;
        }
      }
    }
  }
}

.sign-in {
  .account-links__profile,
  .account-links__orders,
  .account-links__favorites {
    height: auto !important;
  }
}
