$enable_cookie-img: true !default;

.popup-offer__sign-up-form-error-messages {
  color: red;
  padding: 10px 0px;
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      letter-spacing: 1px;
      word-wrap: break-word;
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
  .resp-tabs-list {
    .resp-tab-item {
      letter-spacing: 0.5px;
    }
  }
  .store-locator__form-container {
    .store-search-controls {
      a.country {
        width: 200px;
      }
      input.search {
        width: 230px;
      }
    }
  }
  #map-canvas {
    .gmnoprint {
      div[title^='Nach links verschieben'],
      div[title^='Nach rechts verschieben'],
      div[title^='Nach oben verschieben'],
      div[title^='Nach unten verschieben'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .site-nav {
    .menu__item--lvl-1 {
      vertical-align: top;
    }
  }
}

.search-form.search-form--results {
  .search-form__fields {
    .search-form__submit {
      letter-spacing: 0.3px;
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
}

.section-esearch {
  #search-wrapper.wrap {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          .product-grid__content {
            max-width: 1440px;
            display: flex;
            flex-wrap: wrap;
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 30px 0 30px 0;
  text-align: left;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 40px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type='text'] {
  width: 250px;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 20px;
}

.waitlist_thankyou_message {
  font-family: 'Brandon Text Bold';
  margin: 130px 60px 90px 115px;
  line-height: 1.45em;
}

.skincare-finder {
  .product-filter {
    @include breakpoint($large-up) {
      float: none;
      &:first-child {
        float: none;
        margin-bottom: 30px;
      }
    }
    &__label {
      text-align: center;
      display: block;
    }
    @media (max-width: 768px) {
      &__filter {
        padding: 0 5px;
      }
    }
  }
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    height: auto;
  }
}

.customer-service-section {
  h2 {
    &.customer-service-landing-block__header {
      a {
        position: static;
      }
    }
  }
}

.customer-service-landing {
  .customer-service-section div {
    #customer_service_chat {
      display: block;
    }
  }
}

.customer-service-section {
  #landing-chat {
    #customer_service_chat {
      a {
        top: 0px;
      }
    }
  }
}

.site-footer {
  .site-footer-contact__item {
    &--phone {
      width: 40%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        letter-spacing: 0px;
        padding: 1em 5px;
        word-wrap: break-word;
      }
    }
  }
  .site-footer {
    .site-email-signup {
      .site-email-signup__field {
        width: 53%;
        .i18n-fr-e-SWI & {
          width: 60%;
          padding: 5px 5px;
        }
      }
      .site-email-signup__submit {
        letter-spacing: 1px;
        width: 44%;
        padding: 0px;
        .i18n-fr-e-SWI & {
          letter-spacing: 0px;
          width: 37%;
          padding: 0px;
          font-size: 13px;
        }
      }
    }
    .sticky-back-to-top {
      top: 63%;
    }
    .site-footer-contact__item {
      width: 50%;
    }
  }
  .customer-service-menu {
    a.menu__link--lvl-1 {
      width: auto;
    }
  }
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        .customer-service-landing-block__link {
          bottom: 5px;
          padding-bottom: 0;
        }
      }
    }
  }
  .store-locator--desktop {
    .store-locator__main {
      .column:last-child {
        width: 35%;
        margin: 0 0;
      }
    }
    ul.resp-tabs-list {
      li.resp-tab-item:first-child {
        width: 70%;
      }
      li.resp-tab-item {
        width: 30%;
      }
    }
    .store-locator__directions-location {
      padding: 5px;
      .directions-form__input-wrapper {
        .store-locator__directions-destination {
          width: 25px;
          margin-right: 5px;
        }
        .directions-form__input {
          width: 212px;
        }
      }
      .directions-form__submit {
        margin: 0 0 22px 45px;
      }
    }
    .store-locator__tooltip.info_window,
    .store-locator__tooltip.info_window:last-child {
      width: 100%;
    }
  }
  .site-nav {
    .menu__item--lvl-2 {
      width: 25%;
    }
    .menu__item--child-count-4 {
      .menu__item--lvl-2.first {
        margin-left: 0%;
      }
    }
  }
  .makeup-lessons-items {
    .makeup-lessons-items__meta {
      float: left;
      width: 65%;
      margin-left: 25px;
      .makeup-lessons-items__lessons {
        position: relative;
        bottom: 0px;
        padding-left: 0px;
        margin-left: 0px;
        .makeup-lessons-item:nth-child(5) {
          margin-left: 0px;
        }
        .makeup-lessons-item {
          margin-bottom: 10px;
        }
      }
    }
  }
  .viewcart {
    .recommended-products-panel__content {
      margin: 0 3%;
      .add_button {
        input.btn {
          width: 100%;
          white-space: normal;
          line-height: 18px;
          height: 50px;
        }
      }
    }
  }
  .offers-details {
    .offers-details-items {
      .offers-details-item__link-container {
        padding: 10px 30px 0 10px;
        text-align: center;
        .offers-details-item__link {
          padding: 5px;
          margin: 0px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .site-footer {
    .site-footer-contact__item {
      &--phone {
        width: 45%;
      }
      &--email {
        width: 50%;
      }
    }
  }
  .viewcart {
    .recommended-products-panel__content {
      margin: 0 3%;
    }
  }
}

.customer-service-contact-form__country-select.dropdown::-ms-expand {
  display: block;
}

.site-email-sms-thank-you {
  .site-email-sms-thank-you__header {
    h1 {
      height: auto;
    }
  }
}

.site-footer {
  .site-email-signup {
    .site-email-signup__submit {
      height: 32px;
    }
  }
}

.site-utils__dropdown--account {
  #registration__form {
    input {
      width: 100%;
    }
    .registration__show-pass {
      margin: 5px 0 0 0;
    }
  }
}

.ie8 {
  .registration #registration__form {
    span.registration__terms-text {
      margin-top: 10px;
      padding-left: 0px;
    }
  }
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

.quickshop__container {
  .product__details {
    .product__actions {
      a {
        letter-spacing: 0px;
      }
      a.product__favorite {
        padding-right: 0px;
      }
    }
  }
}

.bobbis-lessons__container {
  .bobbis-lessons__carousel {
    .bobbis-lessons__preview--prev {
      display: none;
    }
    .bobbis-lessons__preview--next {
      display: none;
    }
  }
}

#colorbox.popup-offer-cbox {
  .popup-offer__outer-wrap {
    padding: 10px 25px 0 25px;
    .popup-offer__heading {
      margin-bottom: 10px;
    }
    .popup-offer__body {
      p {
        margin-bottom: 15px;
      }
    }
  }
}

.homepage-hero__abstract--left {
  .homepage-hero__abstract__inner {
    padding: 50px 50px 0 50px;
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    li#category-products.customer-service-contact-form__category {
      letter-spacing: 0px;
      padding: 25px 0px 0px 0px;
    }
    li#category-service.customer-service-contact-form__category {
      letter-spacing: 1px;
      padding: 1em 0px;
    }
  }
}

.site-header {
  .site-utils__links {
    .site-utils__item--live-chat {
      .site-utils__link-text {
        display: block;
      }
      .site-utils__icon {
        display: none;
      }
    }
  }
}

.offers-details {
  .offers-details-items {
    .offers-details-item__button-container {
      float: right;
    }
  }
}

.endeca-product-result {
  .product__button--add-to-bag {
    display: none !important;
  }
}

.sticky-chat {
  .language-select__link--selected {
    text-decoration: underline !important;
    color: #ffffff;
  }
  .language-select__item--01 {
    border-right: solid 1px #ffffff !important;
  }
}

.page-products-15608,
.page-products-15609,
.page-products-15610,
.page-products-15615 {
  .page-header {
    .page-header__title {
      display: none;
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    position: relative;
    em#one-offer-only {
      span {
        a {
          letter-spacing: 1.75px;
          font-size: 10px;
          position: absolute;
          bottom: 5px;
          right: 0px;
        }
      }
    }
  }
}

.bobbis-lessons {
  .bobbis-lessons__footer {
    .link {
      line-height: 0px;
    }
  }
}

.skin__foundation__dropdown__select__copy {
  width: auto !important;
}

.makeup-lesson__event {
  .makeup-lesson__event-tip__author {
    display: none;
  }
}
/* Power reviews */
.i18n-de-e-SWI {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-helpful {
          &-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ja ';
                visibility: visible;
              }
            }
          }
          &-no {
            .pr-helpful-count {
              &:before {
                content: 'Nein ';
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}

.i18n-fr-e-SWI {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-helpful {
          &-yes {
            .pr-helpful-count {
              &:before {
                content: 'Oui ';
                visibility: visible;
              }
            }
          }
          &-no {
            .pr-helpful-count {
              &:before {
                content: 'non ';
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}

#pr-war-form {
  .tag-group {
    .input-add-tag {
      width: 84%;
    }
  }
  .pr-skinconcern-form-group,
  .pr-produktvorteile-form-group {
    .pr-control-label {
      float: left !important;
      margin-right: 20px;
    }
  }
}

.js-sticky-header {
  .js-site-header-sticky {
    .site-utils__inner {
      max-width: 100%;
    }
  }
}

.editorial-product-grid {
  .product-brief {
    &__bottom {
      margin: 0 auto 8px;
    }
  }
}

.site-email-sms-signup {
  $self: &;
  .site-email-sms-signup__agree {
    input {
      &#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS {
        position: absolute;
      }
    }
  }
  #{$self} {
    &__header {
      h1 {
        font-size: 2em;
      }
      h2 {
        letter-spacing: 0;
        margin-bottom: 10px;
      }
    }
  }
}
