.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

label.error {
  color: $color-red;
}

.cart_overlay {
  .cart-confirm-wrapper {
    .full_view {
      .price {
        .currency_type {
          display: none;
        }
      }
    }
  }
}

.country-selectBox-dropdown-menu {
  li:first-child {
    display: none;
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .error-messages {
        color: #ff4661;
      }
    }
  }
}

.appt-book {
  #appointment-book-sections {
    .appointment-select {
      .appointments-container {
        .confirm-container {
          width: 55%;
        }
        @include breakpoint($tablet-down) {
          .confirm-container {
            width: 64%;
            .total-time {
              width: auto !important;
            }
          }
        }
      }
    }
    .confirmation-container,
    .appointment-details {
      .date-time {
        width: 100%;
        border-right: none;
        .appt-date-cal {
          background-position: 27.5em 0.5em;
          .cals-container.expanded {
            left: 42%;
          }
        }
      }
      .artist {
        display: none;
      }
    }
  }
}

.appt-book {
  .appt-book-location-selector {
    .location-select {
      width: 500px !important;
    }
    @include breakpoint($tablet-down) {
      .location-submit {
        margin: 20px auto;
        float: none;
      }
    }
  }
}

@include breakpoint($tablet-down) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 92px !important;
      width: 800px !important;
      margin-left: -410px !important;
      padding-right: 10px;
    }
  }
}
