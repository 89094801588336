.checkout {
  &__panel-title {
    margin-bottom: 1.5em;
  }
  &__content {
    .cart-item {
      &__price {
        width: 19%;
      }
      &__total {
        width: 15%;
      }
    }
    .total_column.sub__total {
      margin: 0.8em 0;
      float: right;
    }
  }
  .cross_sell_container {
    .cart-item__desc-info {
      .color {
        margin-bottom: 1em;
      }
    }
  }
  &.samples-page {
    .product {
      height: 460px;
      word-wrap: break-word;
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-right: 0;
      }
    }
    .samples-buttons.bottom {
      display: block;
    }
  }
  ol.checkout-progress li {
    float: left;
    margin-left: 1.5em;
    width: 12.5em;
    list-style-type: decimal;
    margin-top: 1.5em;
  }
  //Control directive to highlight checkout breadcrumb
  $checkout: (shipping, review, billing, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress__#{$value} {
        font-weight: bold;
      }
    }
  }
  #address_form_container {
    .title_container,
    .phone_label_container,
    .country_container {
      margin-bottom: 10px;
    }
  }
  .checkout-header {
    margin: 1.5em 0;
    .checkout-co-enabled & {
      margin: 0;
    }
  }
  .checkout-buttons-container {
    margin-bottom: 4em;
    .btn {
      float: right;
    }
  }
  .product.deactivate {
    .sample-select-button {
      background: $color-gray-light;
    }
  }
  .transaction-panel-details {
    margin-top: 2em;
    .trans_detail_item {
      float: left;
      margin-bottom: 2em;
      margin-right: 2em;
      position: relative;
      width: 19.5em;
      word-wrap: break-word;
      .change_link {
        position: absolute;
        top: 0.6em;
        right: 0;
      }
    }
    .transaction-item.trans_detail_item.gift_container {
      clear: both;
    }
  }
  address {
    font-style: normal;
  }
  #shipping-page-info-panel {
    .shipping-address-info,
    .billing-info {
      .address-container {
        float: left;
        margin: 0 3em 0.8em 1.5em;
        width: 15em;
      }
      .address_controls {
        float: left;
        #choose-address {
          margin-top: 0.8em;
          width: 24em;
        }
      }
    }
    .checkout-buttons-container {
      margin-bottom: 0;
    }
  }
  .giftwrap-info {
    margin-bottom: 1.5em;
    #gift-options {
      .shipping-panel__title {
        line-height: 18px;
        text-indent: 0;
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid $color-black;
        background-color: $color-white;
      }
    }
  }
  #emailpromo-info-panel {
    float: left;
    margin-top: 20px;
  }
  .sms_promotions {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .payment-type {
    margin-bottom: 1em;
  }
}

#confirm {
  #confirmation-email {
    margin-bottom: 0.8em;
  }
  .return-link-container {
    margin-bottom: 2em;
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .order-totals {
    width: 55%;
    float: right;
    th,
    tr,
    td {
      border: 0;
    }
    td {
      text-align: right;
    }
  }
  .cart-item:last-child {
    border-bottom: 1px solid $color-light-gray;
    padding-top: 20px;
  }
  .order-details-page {
    &__content .cart-item {
      &__thumb {
        width: 16%;
        padding-right: 0;
        margin-right: 10px;
      }
      &__desc {
        width: 29%;
        float: left;
        margin-left: 10px;
      }
      &__price {
        width: 21%;
      }
      &__qty {
        width: 20%;
        clear: none;
        float: left;
        margin: 0 10px;
      }
      &__total {
        width: 9%;
        float: left;
        text-align: right;
      }
    }
  }
}

.checkout__sidebar {
  .account-utilities {
    &__section {
      background: $color-gray-background;
      padding: 16px 20px;
    }
    &__header {
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
  #links-panel {
    margin-top: 6px;
    border: 5px solid $color-nude;
    padding: 15px 15px 20px 15px;
    background: $color-white;
    .link {
      text-decoration: none;
      display: block;
      border: 0;
      line-height: normal;
    }
  }
  #order-summary-panel {
    .tax.label {
      line-height: 40px;
    }
  }
  #offer-code-panel {
    #one-offer-only {
      font-style: normal;
      margin: 18px 0 14px 0;
      display: block;
    }
  }
}

.checkout__content {
  .cart-items .cart-item__desc-container {
    .cart-item__qty {
      width: 25%;
    }
    .cart-item__price {
      width: 18%;
    }
    .cart-item__total {
      width: 19%;
    }
  }
  .shipping-page-info-panel {
    section {
      margin-bottom: 10px;
    }
    .billing-info {
      .address_form_container {
        span div {
          margin-bottom: 10px;
        }
      }
      #billing_address_form_container {
        border: 1px solid #000;
        padding: 20px;
      }
      .address-container.billing-address {
        margin-left: 0px !important;
      }
    }
    .delivery-info {
      p,
      h2 {
        margin-bottom: 10px;
      }
    }
    .emailpromo-info {
      h3.newsletter-info__header {
        text-indent: 0px;
      }
      p {
        margin-left: 0px;
        margin-bottom: 10px;
      }
      .newsletter-info__item {
        margin-left: 0px;
        padding-left: 0px;
      }
    }
    .gift-info {
      .gift-options-container {
        margin-top: 10px;
      }
    }
    .shipping-address-info {
      .address-container.shipping-address {
        margin-left: 0px !important;
      }
    }
  }
}

.checkout__sidebar {
  .offer-code-panel {
    .checkout__panel-title {
      margin-bottom: 0px;
    }
  }
  section {
    margin-bottom: 10px;
  }
}

.review {
  .shipping-panel #review-address-info {
    .transaction-panel-details {
      .shipping_container.clear {
        clear: none;
      }
      .gift_container {
        clear: both;
      }
    }
  }
  #checkout_review {
    h3 {
      text-indent: 0px;
    }
    p {
      margin-bottom: 15px;
    }
  }
}

.billing {
  .checkout-buttons-container {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 15px;
  }
  .transaction-panel-details {
    .clear.shipping_container {
      clear: none;
    }
  }
  .payment_method_container {
    margin-bottom: 10px;
    .payment-type {
      margin-bottom: 10px;
      label {
        display: block;
        overflow: hidden;
        height: 60px;
        padding: 20px 0px;
        img {
          position: absolute;
          margin-top: -7px;
          left: 30px;
        }
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label
        img {
        width: 60px;
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label img {
        margin-top: -20px;
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_amex_label
        img {
        margin-top: -20px;
      }
    }
  }
}

.order-details {
  .order-details-page {
    .cart-item {
      &:last-child {
        border-bottom: 1px solid $color-gray-border;
      }
    }
  }
}

.fr_CH {
  .review {
    .shipping-panel {
      h4.checkout__subtitle {
        padding-right: 60px;
      }
    }
  }
  .checkout ol.checkout-progress li {
    width: 14.5em;
  }
}

.de_CH {
  .review {
    .shipping-panel {
      h4.checkout__subtitle {
        padding-right: 80px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .viewcart {
    .viewcart-buttons-panel {
      .continue-buttons {
        width: 50%;
      }
      a.continue-shopping {
        width: 45%;
        float: left;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .fr_CH {
    .checkout {
      ol.checkout-progress {
        li {
          width: 10.5em;
        }
        li:first-child {
          width: 14.5em;
        }
      }
    }
  }
  .de_CH {
    .checkout {
      ol.checkout-progress {
        li {
          width: 11.5em;
        }
      }
    }
  }
}

.viewcart-buttons-panel {
  .continue-buttons {
    a.disabled {
      background-color: #cccccc;
      cursor: default;
    }
  }
}

.review {
  .checkout-buttons-container {
    input.disabled {
      float: right;
      background-color: #cccccc;
      cursor: default;
    }
  }
}

.checkout ol.checkout-progress li {
  margin: 24px 24px 0;
  width: auto;
}

.pg-checkout {
  overflow: hidden;
  .site-header {
    nav {
      &.site-nav--primary {
        display: block;
      }
    }
  }
}

.checkout-co-enabled {
  .pg_wrapper {
    .checkout {
      &__sidebar {
        .offer-code-panel {
          &__title {
            border-bottom: none;
            padding-bottom: 0;
          }
          &__content {
            margin-top: 10px;
            .offer_code_form_container {
              #one-offer-only {
                .continue-shopping {
                  position: static;
                }
              }
            }
          }
        }
      }
    }
  }
}

.enhanced-delivery-page {
  .column.left {
    #checkout_complete{
      .wrapper {
        #payment-info-panel {
          .cc-header {
            display: none;
          }
          #payment-type-cc {
            .payment-type {
              img {
                margin-left: -5px;
              }
            }
          }
        }
      }
    }
  }
}
