.enhanced-delivery-page {
  .checkout__sidebar {
    #order-summary-panel {
      .tax.label {
        line-height: 1.4;
      }
    }
  }
  .pg_wrapper {
    .column.left {
      #checkout_complete {
        .wrapper {
          #payment-info-panel {
            #payment-type-ncc {
              border-bottom: 0;
              border-top: 1px solid $color-nobel;
              .payment-type {
                border-bottom: 1px solid $color-nobel;
              }
            }
            #payment-type-cc {
              .payment-type {
                border-bottom: 0;
              }
              .group2 {
                .payment-type {
                  border-top: 1px solid $color-lighter-gray;
                }
              }
            }
          }
        }
      }
      .wrapper {
        .edit-address-container {
          .select-arrow {
            &::before {
              #{$rdirection}: 15px;
              border: 5px solid transparent;
              border-top-color: $color-grey-shadow;
              bottom: 20px;
              content: '';
              pointer-events: none;
              position: absolute;
            }
          }
        }
        #delivery-options {
          display: none;
        }
        #checkout_shipping {
          #billing-address-info-panel {
            .address_controls {
              li {
                .edit-billing-address {
                  top: 38px;
                }
              }
            }
          }
          .shipping-address-info {
            .edit-address-container,
            #address_form_container,
            .shipping-address-form-container {
              .country_container {
                background: none;
                border: 0;
                color: $color-white;
                margin: 22px 0 0;
                padding: 0;
                .selectBox {
                  height: 54px;
                  padding: 10px 15px;
                  text-transform: none;
                  &::after {
                    #{$rdirection}: 8px;
                    border: 5px solid transparent;
                    border-top-color: $color-grey-shadow;
                    content: '';
                    float: #{$rdirection};
                    pointer-events: none;
                    position: relative;
                    top: 13px;
                  }
                }
                .selectBox-arrow {
                  display: none;
                }
              }
            }
            .shipping-address-form-container,
            .edit-address-container,
            #address_form_container {
              .country_container {
                @include swap_direction(padding, 17px 20px 13px 15px);
                background: $color-light-brown;
                border: 1px solid;
                color: $color-black;
              }
            }
          }
        }
        .continue-shopping {
          position: relative;
        }
        #emailpromo-info-panel {
          float: none;
        }
      }
    }
  }
  &#payment {
    .column.right {
      &.checkout__sidebar {
        #shipping-panel {
          #review-address-info {
            .transaction-panel-details {
              .transaction-item {
                &.transaction-details-content.shipping_container {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
